import * as React from 'react';

import { EmbarkProvider } from '@emburse/embark-core';
// @ts-ignore
import { AdapterDateFns } from '@emburse/embark-core/dist/AdapterDateFns';
import { EmburseLocaleProvider, defaultEmbarkLocale } from '@emburse/embark-locale';

import { AbacusThemeProvider } from '@ReactComponents/Layout/ThemeProvider';

const AbacusEmbarkProvider: React.FC = function ({ children }) {
  return (
    <EmburseLocaleProvider value={{ i18n: defaultEmbarkLocale() }}>
      <EmbarkProvider
        featureFlags={{ useNewColorScheme: true, legacyI18nSupport: true, useGridProFeatures: true }}
        theme={{ designToken: AbacusThemeProvider.getThemeOptions(), responsive: { sidebar: true } }}
        dateLocale={{ dateAdapter: AdapterDateFns }}
      >
        {children}
      </EmbarkProvider>
    </EmburseLocaleProvider>
  );
};

export { AbacusEmbarkProvider };
